import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { navigate } from 'gatsby';
import { Loading } from '../components/layout';

import { logOut } from '../store/actions/login';

const Logout = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(logOut());
    navigate('/landing');
  }, []);
  return (
    <div className="d-flex flex-column justify-content-center align-items-center p-5">
      <Loading iconColor="amarillo" />
    </div>
  );
};

export default Logout;
